<!--
 * @Author: your name
 * @Date: 2020-11-10 08:47:10
 * @LastEditTime: 2021-02-05 13:57:57
 * @LastEditors: yangliao
 * @Description: 缴费记录
 * @FilePath: /netHallOfficialAccounts/src/views/WaterAccount/RechargeRecord.vue
-->
<template>
  <div class="rechargeRecord">
    <div :class="selectedFlag ? 'rechargeRecord-header' : 'rechargeRecord-header-selected'">
      <!-- <span>账户筛选</span> -->
      <van-dropdown-menu active-color="#1677FF" :close-on-click-overlay="false">
        <van-dropdown-item :title="accountScreen" ref="itemOne">
          <div class="content">
            <van-radio-group v-model="result" ref="checkboxGroup" @change="getRadioValue">
              <van-radio v-for="(item, index) in topDatainfo" :key="index" :name="item.wuserNo">
                <div class="right-txt">
                  <div class="first-li">
                    <span>{{ item.wuserName }} 户号: {{ item.wuserNo }}</span>
                  </div>
                  <div class="center-li van-multi-ellipsis--l2">
                    <p>
                      <em v-if="item.labelName && item.labelName.length > 0">{{
                        item.labelName
                      }}</em
                      >{{ item.wuserAddress }}
                    </p>
                  </div>
                </div>
              </van-radio>

              <div class="bottom-content">
                <van-radio name="all">全部账户</van-radio>
              </div>
            </van-radio-group>
          </div>
        </van-dropdown-item>
      </van-dropdown-menu>
    </div>

    <van-pull-refresh
      v-model="refreshing"
      @refresh="onRefresh"
      :disabled="true"
      v-if="dataInfoFlag"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="selectRechargeRecord"
      >
        <div class="rechargeRecord-list">
          <ul>
            <li v-for="(item, index) in dataInfo" :key="index">
              <div class="mainInfo">
                <span
                  ><em>缴费金额：¥ {{ item.arrearsAmount }}</em></span
                >
              </div>
              <div class="middInfo">
                <span> {{ item.wuserName }}「{{ item.wuserNo }}」</span>
                <span>账单金额：¥ {{ item.payAmount }}</span>
              </div>
              <div class="middInfo">
                <span>本次预存：¥ {{ item.balancePay }}</span>
                <span>缴费方式：{{ item.payWay }}</span>
              </div>
              <div class="subInfo">
                <span>{{ item.payTime }}</span>
              </div>
            </li>
          </ul>
        </div>
      </van-list>
    </van-pull-refresh>

    <no-data v-else />
  </div>
</template>

<script>
import NoData from '@/components/noData';
import { selectAccount, selectRechargeRecord, payAgain } from '../../api/home';
import { formatTime } from '@/utils/tools';

export default {
  name: 'RechargeRecord',
  components: {
    NoData,
  },
  data() {
    return {
      accountScreen: this.$route.query.wuserNo,
      topDatainfo: [], // 账单筛选
      result: 'all',
      accountList: [],
      wuserNo: this.$route.query.wuserNo,
      // 分页
      refreshing: false,
      loading: false,
      finished: false,
      currentPage: 1,
      size: 5,
      dataTotal: 0,
      dataInfoFlag: true,
      dataInfo: [],
      selectedFlag: true, // 反馈类型筛选的颜色控制
    };
  },
  mounted() {
    this.selectAccount();
    this.result = this.$route.query.wuserNo;
  },
  methods: {
    // 账户筛选数据
    async selectAccount() {
      const { status, resultData } = await selectAccount();
      if (status === 'complete') {
        this.topDatainfo = resultData;
        let tempAccount = [];
        let indexList = [];
        resultData &&
          resultData.map((item, index) => {
            tempAccount.push(item.wuserNo);
            if (item.selected) {
              indexList.push(index);
            }
          });
        this.accountList = tempAccount;
      }
    },
    // 列表
    async selectRechargeRecord() {
      const arg = {
        wlist: this.wuserNo == '' ? this.accountList : [this.wuserNo],
        page: {
          current: this.currentPage,
          size: this.size,
        },
      };
      console.log('参数---', arg);
      const { status, resultData } = await selectRechargeRecord(arg);
      if (status === 'complete') {
        if (resultData && resultData.records.length > 0) {
          this.dataInfoFlag = true;
          resultData.records &&
            resultData.records.map((item) => {
              item.createdTime = formatTime(item.createdTime);
            });
          // 分页
          this.dataTotal = resultData.total;
          this.dataInfo = this.dataInfo.concat(resultData.records);
          this.currentPage++;
          // 没有更多数据
          if (this.dataInfo.length >= Number(this.dataTotal)) {
            this.finished = true;
          } else {
            this.finished = false;
          }
        } else {
          this.dataInfo = [];
          this.dataInfoFlag = false;
          this.finished = true;
        }
      } else {
        this.finished = true;
      }
      this.loading = false;
    },
    // 单选按钮事件
    getRadioValue(value) {
      if (value !== 'all') {
        this.accountScreen = value;
        this.wuserNo = value;
      } else {
        this.accountScreen = this.accountList.join(',');
        this.wuserNo = '';
      }
      this.selectedFlag = false; // 反馈类型筛选的颜色控制
      this.dataInfo = []; // 增加
      this.currentPage = 1;
      // this.finished = false;
      this.selectRechargeRecord();
      this.$refs.itemOne.toggle();
    },
    // 下拉刷新
    onRefresh() {
      this.currentPage = 1;
      this.dataInfo = []; // 增加
      this.finished = false;
      this.selectRechargeRecord();
      this.loading = false;
    },
    // 立即支付
    async clickPayAgain(arg) {
      const { status, resultData } = await payAgain(arg);
      if (status === 'complete') {
        if (Object.keys(resultData).length === 0) {
          if (typeof WeixinJSBridge === 'undefined') {
            if (document.addEventListener) {
              document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
            } else if (document.attachEvent) {
              document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
              document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
            }
          }
        } else {
          this.onBridgeReady(resultData);
        }
      }
    },
    // 微信支付：
    onBridgeReady(params) {
      var that = this;
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
          appId: params.appId, //公众号名称，由商户传入
          timeStamp: params.timeStamp, //支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: params.nonceStr, //支付签名随机串，不长于 32 位
          package: params.packageValue, //统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: params.signType, //签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: params.paySign, //支付签名
        },
        function(res) {
          if (res.err_msg === 'get_brand_wcpay_request:ok') {
            console.log('成功', res.err_msg);
            that.$router.push({
              name: 'PaymentResult',
              path: '/QueryPay/PaymentResult',
              query: {
                name: 'QueryPay',
              },
            });
          } else if (res.err_msg === 'get_brand_wcpay_request:fail') {
            console.log('失败', res.err_msg);
          }
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
.rechargeRecord {
  .rechargeRecord-header {
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 10px 16px;
    font-size: 14px;
    background: #ffffff;

    /deep/.van-dropdown-menu__title {
      padding: 0px;
      width: 110px;
      text-align: left;
      color: rgba(0, 0, 0, 0.65);
    }
    /deep/.van-dropdown-menu__title::after {
      border-color: transparent transparent rgba(0, 0, 0, 0.65) rgba(0, 0, 0, 0.65);
    }
    /deep/.van-dropdown-menu__bar {
      height: auto;
      background: transparent;
      box-shadow: none;
    }
    /deep/.van-dropdown-menu__item {
      margin-right: 10px;
      flex: none;
    }
  }
  // 账户筛选-选中样式
  .rechargeRecord-header-selected {
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 10px 16px;
    font-size: 14px;
    background: #ffffff;
    /deep/.van-dropdown-menu__bar {
      height: auto;
      background: transparent;
      box-shadow: none;
    }
    /deep/.van-dropdown-menu__title {
      width: 110px;
      color: #1677ff;
    }
    /deep/.van-dropdown-menu__title::after {
      border-color: transparent transparent #1677ff #1677ff;
    }
  }

  // 账户筛选
  .content {
    padding-top: 5px;
    padding-bottom: 10px;
    ul {
      li {
        width: calc(100% / 3 - 17px);
        display: inline-block;
        text-align: center;
        border: 1px solid #eeeeee;
        border-radius: 3px;
        margin-right: 15px;
        margin-top: 15px;
        position: relative;
        overflow: hidden;
        span {
          display: inline-block;
          height: 32px;
          line-height: 32px;
          font-family: PingFangSC-Medium;
          font-size: 14px;
          color: #6e6e6e;
          text-align: center;
        }
        // 选中样式
        .triangle {
          width: 30px;
          height: 30px;
          background: #1677ff;
          position: absolute;
          bottom: -15px;
          right: -15px;
          transform: rotate(45deg);
          i {
            position: absolute;
            bottom: 5px;
            right: 5px;
            display: block;
            width: 18px;
            text-align: center;
            color: #ffffff;
            transform: rotate(-25deg);
          }
        }
        .triangle::after {
          display: inline-block;
          content: '';
          width: 30px;
          height: 35px;
          background: #ffffff;
          transform: rotate(-45deg);
          position: absolute;
          bottom: 18px;
          right: 0px;
          z-index: 20;
        }
      }
      li:last-child {
        margin-right: 0px;
      }
      // 选中的样式
      .currentSpan {
        color: #0a79ec;
        background: #cde4ff;
      }
    }
    // 账单筛选
    .right-txt {
      .first-li {
        margin-left: 10px;
        margin-right: 10px;
        display: flex;
        justify-content: space-between;
        span {
          font-family: PingFangSC-Medium;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.85);
          letter-spacing: 0;
          line-height: 20px;
          font-weight: 600;
        }
      }
      .center-li {
        margin-left: 10px;
        em {
          font-size: 12px;
          padding: 1px 5px;
          font-style: normal;
          font-family: PingFangSC-Regular;
          color: #637fb7;
          letter-spacing: 0;
          line-height: 16px;
          background: #ecf1fe;
          border-radius: 2px;
        }
        p {
          margin: 0px;
          font-size: 14px;
          font-family: PingFangSC-Regular;
          color: rgba(0, 0, 0, 0.65);
          letter-spacing: 0;
          line-height: 22px;
          text-align: left;
        }
      }
    }
    .van-radio {
      padding-bottom: 10px;
    }
    .van-radio-group {
      margin-left: 16px;
      margin-right: 16px;
      margin-bottom: 15px;
    }
    .bottom-content {
      position: relative;
      // border-top: 1px solid rgba(0,0,0,0.08);
      display: flex;
      justify-content: space-between;
      padding-top: 15px;
      // padding-left: 16px;
      // padding-right: 16px;
      // padding-bottom: 16px;
      .sureBtn {
        width: 79px;
        border-radius: 4px;
        font-size: 14px;
      }
      .van-radio {
        padding-bottom: 0px;
      }
    }
    .bottom-content::after {
      position: absolute;
      box-sizing: border-box;
      content: ' ';
      pointer-events: none;
      right: 0px;
      top: 0;
      left: 0px;
      border-top: 1px solid #ebedf0;
      -webkit-transform: scaleY(0.5);
      transform: scaleY(0.5);
    }
  }
  // 列表
  .rechargeRecord-list {
    margin: 16px;
    ul {
      li {
        margin-bottom: 15px;
        background: #ffffff;
        border-radius: 4px;
        .mainInfo {
          display: flex;
          justify-content: space-between;
          margin-left: 16px;
          margin-right: 16px;
          padding-top: 14px;
          padding-bottom: 14px;
          span {
            font-size: 14px;
          }
          em {
            font-style: normal;
            font-size: 16px;
            font-weight: bold;
          }
        }
        .middInfo {
          display: flex;
          justify-content: space-between;
          margin-left: 16px;
          margin-right: 16px;
          padding-bottom: 14px;
          span {
            font-size: 14px;
          }
        }
        .subInfo {
          display: flex;
          justify-content: space-between;
          padding-top: 14px;
          padding-bottom: 14px;
          margin-left: 16px;
          margin-right: 16px;
          border-top: 1px solid rgba(0, 0, 0, 0.08);
          span {
            color: #cccccc;
            font-size: 14px;
          }
          .van-button {
            border-radius: 8px;
            font-size: 12px;
            span {
              color: #1989fa !important;
            }
          }
          // 默认到帐中颜色
          em {
            font-style: normal;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.45);
          }
          .success {
            color: #25b1bf;
          }
          .failed {
            color: #f76f7e;
          }
          .closed {
            color: rgba(0, 0, 0, 0.45);
          }
        }
      }
      li:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
</style>
